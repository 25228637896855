<template>
  <div :class="$style.container">
    <OrderCard
      :date="order.createdAt"
      :name="order.name"
      :price="order.productsPrice"
      :sale="saleInValue"
      :saleInPercent="order.discountSize"
      :deliveryPrice="order.deliveryPrice"
    ></OrderCard>
    <div
      v-for="item of order.orders"
      :key="item.order.id"
      :class="$style.infoOrderCard"
      @click="
        $router.push(
          getRoute({
            route: $options.ADDSELLERS_ADMIN_ROUTES.ORDERS.ADDSELLERS.VIEW,
            params: { id: item.order.id },
          }),
        )
      "
    >
      <InfoOrderCard
        :warehouse="item.order.warehouse"
        :price="item.order.price"
        :order-products="item.orderProducts"
        :payment="getOrderPayment(item.order.payment)"
        :delivery-price="item.order.deliveyPrice"
        :discount="item.order.discountSize"
        :bonusWriteOff="item.order.bonusTransactionWriteOffValue"
      />
    </div>
  </div>
</template>
<script>
import delivery from '@/delivery'
import { ADDSELLERS_ADMIN_ROUTES, getRoute } from '@/constants/routing'
import { PAYMENT_TYPES_DISPLAY } from '@/constants/paymentTypes'

import OrderCard from '@/components/atoms/cards/OffsellersOrderCard.vue'
import InfoOrderCard from '@/components/atoms/cards/SellersOrderCard.vue'

export default {
  components: { OrderCard, InfoOrderCard },
  ADDSELLERS_ADMIN_ROUTES,
  PAYMENT_TYPES_DISPLAY,
  middleware: ['isAuth', 'isVerified'],
  data() {
    return {
      order: {},
    }
  },
  async mounted() {
    await this.getOrder()
  },
  computed: {
    saleInValue() {
      return Number(
        ((this.order?.productsPrice / 100) * this.order?.discountSize).toFixed(
          2,
        ) ?? 0,
      )
    },
  },
  methods: {
    async getOrder() {
      const { value, error } =
        await delivery.AddwineCore.OrdersActions.getOffSellersById(
          this.$route.params.id,
        )

      if (error) return

      this.order = value
    },
    getRoute({ route, params }) {
      return getRoute({ route, params })
    },
    getOrderPayment(orderPaymentType) {
      return PAYMENT_TYPES_DISPLAY[orderPaymentType] || ''
    },
  },
}
</script>
<style lang="scss" module>
.container {
  @include container;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;

  .infoOrderCard {
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:hover {
      transform: scale(1.03);
    }
  }
}
</style>
