<template>
  <div :class="$style.container">
    <h2 :class="$style.title">
      {{ getWarehouse(warehouse) }}
    </h2>
    <div
      v-for="orderProduct in orderProducts"
      :key="orderProduct.id"
      :class="$style.product"
    >
      <div :class="$style.image">
        <img
          :src="$configData.s3_link + orderProduct.product.mainImage"
          alt="item-image"
        />
      </div>
      <div>
        <div :class="$style.description">{{ orderProduct.product.name }}</div>
        <div :class="$style.code">
          Артикул:
          {{
            orderProduct.product.vendorСode
              ? orderProduct.product.vendorСode
              : 'Артикул не указан'
          }}
        </div>
      </div>
      <div :class="$style.amount">{{ orderProduct.quantity }} шт</div>
      <div :class="$style.price">{{ orderProduct.productPrice }} &#8381;</div>
      <div :class="$style.totalPrice">
        {{ orderProduct.productPrice * orderProduct.quantity }} &#8381;
      </div>
    </div>
    <div :class="$style.payment">
      <div :class="$style.total">
        <div>Итого:</div>
        <div :class="$style.sum">{{ price }} &#8381;</div>
        <div v-if="deliveryType === 'DELIVERY'">(включая доставку).&nbsp;</div>
      </div>
      <div :class="$style.total">
        <div>Скидка:</div>
        <div :class="$style.sum">{{ discount }}&nbsp;%</div>
      </div>
      <div :class="$style.paymentMethod">{{ payment }}</div>
    </div>
    <div :class="$style.paymentMethod">
      Оплачено бонусами: {{ bonusWriteOff }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    warehouse: {
      type: String,
      default: ''
    },
    orderProducts: {
      type: Array,
      default: () => []
    },
    deliveryPrice: {
      type: Number,
      default: 0
    },
    price: {
      type: Number,
      default: null
    },
    payment: {
      type: String,
      default: ''
    },
    deliveryType: {
      type: String,
      default: ''
    },
    discount: {
      type: Number,
      default: 0
    },
    bonusWriteOff: {
      type: Number,
      default: 0
    }
  },
  methods: {
    getWarehouse(value) {
      switch (value) {
        case 'IE':
          return 'Склад ИП'
        case 'LLC':
          return 'Склад ООО'
        case 'RW':
          return 'Удаленный склад'
      }
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/breakpoints.scss';

.container {
  @include card;
  .title {
    @include H200;
  }
  .product {
    padding: 1.5rem 0;
    display: grid;
    grid-template-columns: 2fr 5fr 2fr 2fr 2fr;
    grid-column-gap: 1rem;
    align-items: center;
    @include tablet {
      grid-template-columns: 1fr 5fr 1fr 1fr 1fr;
    }
    @include mobile {
      grid-template-columns: 1.5fr 3fr 1fr 1.4fr 2fr;
      grid-column-gap: 0.5rem;
      font-size: 0.75rem;
    }
    .code {
      padding-top: 0.5rem;
      font-size: 0.875rem;
    }
    .image {
      max-width: 6rem;
      height: 6rem;
      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }
    }
  }
  .payment {
    border-top: 0.0625rem solid $extra-light-gray;
    padding-top: 1.5rem;
    display: flex;
    @include tablet {
      flex-direction: column;
    }
    @include mobile {
      font-size: 0.75rem;
    }
    .total {
      display: flex;
      margin-bottom: 1rem;
      .sum {
        font-weight: 600;
        margin: 0 0.5rem;
      }
    }
  }
}
</style>
