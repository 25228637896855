<template>
  <div :class="$style.orderCard">
    <div :class="$style.infoBlock">
      <span :class="$style.date">Заказ от {{ formatDate(date) }}</span>
      <span :class="$style.name">{{ name }}</span>
    </div>
    <div :class="$style.priceBlock">
      <div>
        Сумма: <span>{{ formatPrice(price) }} &#8381;</span>
      </div>
      <div>
        Скидка: <span>{{ formatPrice(sale) }} &#8381;</span>
      </div>
      <div>
        Скидка(%): <span>{{ saleInPercent }}</span>
      </div>
      <div>
        Доставка: <span>{{ formatPrice(deliveryPrice) }} &#8381;</span>
      </div>
      <div>
        Итого: <span>{{ formatPrice(totalPrice) }} &#8381;</span>
      </div>
    </div>
  </div>
</template>
<script>
import { formatDate } from '@/helpers/index.js'

export default {
  props: {
    date: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    price: {
      type: Number,
      default: 0
    },
    deliveryPrice: {
      type: Number,
      default: 0
    },
    sale: {
      type: Number,
      default: 0
    },
    saleInPercent: {
      type: Number,
      default: 0
    }
  },
  computed: {
    totalPrice() {
      return Number(this.price + this.deliveryPrice - this.sale ?? 0)
    }
  },
  methods: {
    formatDate(date) {
      return formatDate(date)
    },
    formatPrice(price) {
      return price.toFixed(2) ?? 0
    }
  }
}
</script>
<style lang="scss" module>
.orderCard {
  @include card;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .infoBlock {
    display: flex;
    flex-direction: column;

    .date {
      @include H200;
    }

    .name {
      @include text-default;
      color: $cyan;
    }
  }

  .priceBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.5rem;

    & > div {
      @include text-default;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      width: 100%;
      color: $gray;

      & > span {
        @include bold-default;
      }
    }

    & > div:last-child {
      @include bold-default;
      color: $black;
    }
  }
}
</style>
